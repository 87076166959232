export function fetchCustomizationSettingsPageData() {
    const { get: fetchNotification } = useOFetchCustom(`customization/contacts`)
    const customizationData = useCustomizationSettingsData()

    async function fetch() {
        return new Promise((resolve, reject) => {
            fetchNotification(undefined, {
                onSuccess: (response: any) => {
                    customizationData.value = response.data
                    resolve(resolve)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    }
    return { fetch, customizationData }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { fetch } = fetchCustomizationSettingsPageData()

    try {
        await fetch()
    } catch (error: any) {
        return navigateTo("/store")
    }
})
